.button,
.btn {
  -webkit-appearance: none; /* iOS fix */

  display: inline-flex;
  align-items: center;
  gap: var(--grid-gap);
  /* border-radius: 0.5em; */
  padding: 0.5rem 1rem;
  cursor: pointer;

  font-size: var(--interact-size);

  text-decoration: none;
  font-weight: bold;

  color: var(--button-color);

  background: var(--button-bg);
  border: 0.15em solid var(--button-bg);

  .icon {
    fill: var(--button-color);
  }

  &:hover,
  &:focus-visible {
    color: var(--button-bg);
    background: var(--button-color);
    border-color: var(--button-bg);

    .icon {
      fill: var(--button-bg);
    }
  }

  &--inverted {
    color: var(--button-bg);
    background: var(--button-color);
    border-color: var(--button-color);

    .icon {
      fill: var(--button-bg);
    }

    &:hover,
    &:focus-visible {
      color: var(--button-color);
      background: var(--button-bg);
      border-color: var(--button-color);

      .icon {
        fill: var(--button-color);
      }
    }
  }

  &--special {
    --button-color: var(--white);
    --button-bg: var(--blood-orange);
    border-color: var(--blood-orange);
    --icon-color: var(--white);
    border-radius: 2em;
    padding: 0.75em 1.5em;
  }

  &--clean {
    background: none;
    border: none;
    color: var(--button-bg);
    .icon {
      fill: var(--button-bg);
    }
    &:hover,
    &:focus-visible {
      background: none;
      border: none;
      color: var(--button-bg);
      .icon {
        fill: var(--button-bg);
      }
    }
  }

  &--menu-toggle {
    --icon-size: 2em;
    color: var(--button-color);
    .icon {
      fill: var(--button-color);
    }
  }

  &--arrow,
  &--download,
  &--arrow-back {
    --icon-size: 1.2em;
  }

  &--arrow,
  &--download {
    .icon {
      transform: translateX(0.25em);
    }
  }

  &--arrow-back {
    .icon {
      transform: translateX(-0.25em);
    }
  }
}
