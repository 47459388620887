.hero-container {
  height: 100vh;
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .portrait,
  .landscape {
    position: absolute;
    top: 0;
    height: 100%;
    /* width: 100%; */
    img {
      object-fit: cover;
      max-height: 100%;
      /* width: 100%; */
      margin: auto;
    }
  }

  .portrait {
    display: none;

    @media (orientation: portrait) {
      display: flex;
    }
  }

  .landscape {
    display: none;

    @media (orientation: landscape) {
      display: flex;
    }
  }

  .overflow-arrow {
    --icon-size: 5rem;
    --icon-color: var(--white);
    position: absolute;
    bottom: var(--spacing);
    text-align: center;
  }
}

.slogan {
  z-index: var(--z-index-slogan);
  width: 100%;
  span {
    background: var(--hero-bg);
    color: var(--hero-color);
    font-weight: bold;
    font-size: 5rem;
    padding: 0.2em 0.5em;
  }
}

.slogan-wrap {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

html {
  scroll-behavior: smooth;
}

#content-anchor {
  scroll-margin: 6rem;
}
