.plugin--committee {
  h2 {
    margin-bottom: var(--spacing);
  }
}

.committee-members {
  --grid-cell: var(--c3);

  .person {
    position: relative;
  }

  .person__image {
  }

  .person__body {
    --default-height: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--default-height);
    color: var(--white);
    transition: height 0.3s ease-in;
  }

  .person__blend-helper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: var(--pro-futuris-gradient);
    transition: opacity 0.3s ease-in;
  }

  .person:hover,
  .person.active {
    .person__blend-helper {
      opacity: 0;
    }

    .person__body {
      height: var(--full-height);
    }
  }

  .person__body-content {
    mix-blend-mode: hard-light;
    width: 100%;
    height: 100%;
    padding: 0.75em 1em;
    background: var(--pro-futuris-gradient);
  }

  .person__name {
    font-size: 1.1875rem;
    font-weight: 700;
    font-family: PlaifairDisplay, serif;
  }

  .person__name + * {
    margin-top: 0.5em;
  }

  .person__organisation,
  .person__function {
    font-size: 1.125rem;
  }
}
