.testimonials__create-button-container {
  text-align: center;
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}

.testimonials__search {
  background-color: var(--testimonials-bg);
  padding: var(--spacing);

  input.input__search {
    background-color: inherit;
    border-bottom: 1px solid var(--testimonials-color);
    margin-right: 1em;
  }
}

.testimonials-grid {
  display: flex;
  gap: var(--grid-gap);

  &__item {
    margin-top: calc(7.5rem + var(--grid-gap));
    width: 100%;
    display: inline-block;
    position: relative;

    /* background-color: var(--testimonials-bg); */
    padding: var(--spacing);
  }

  &__image {
    position: absolute;
    top: -7.5rem;

    max-height: 9.5rem;
  }

  &__name {
    margin-top: 1em;
    font-weight: bold;
  }

  &__profession {
    margin-bottom: 1em;
  }

  &__statement {
    margin: 1em 0;
    font-weight: bold;
  }

  &__sharing {
    --icon-size: calc(1.5 * var(--interact-size));
    display: flex;
    gap: 0.5em;
  }

  &__separator {
    border-bottom: 1px solid var(--icon-color);
  }

  &__show-more {
    margin: var(--margin) 0;
    display: flex;
    justify-content: center;
    gap: var(--grid-gap);
  }
}

.image-cropping__wrap {
  margin-bottom: var(--grid-gap);
}

.image-cropping {
  display: none;
  max-width: 100%;
  &--show {
    display: block;
  }
}

.testimonials__image-preview {
  margin-bottom: var(--grid-gap);
}

.feat-testimonial {
  display: flex;
  flex-flow: row nowrap;

  &__image {
    flex: 0 1 var(--c4);
    width: 100%;
    object-fit: cover;
  }

  &__separator {
    margin-top: auto;
    border-bottom: 1px solid var(--icon-color);
  }

  &__text {
    display: flex;
    flex-flow: column;
    padding: var(--spacing);
    background-color: var(--testimonials-bg);
    color: var(--white);
    flex: 1 1 var(--c8);

    blockquote {
      font-size: clamp(0.8em, 3vw, 1.5em);
      font-weight: bold;
      margin-bottom: var(--spacing);
    }
    h2 {
      font-size: clamp(1em, 4.5vw, 1.5em);
      margin-top: var(--spacing);
      margin-bottom: 0.2em;
    }
  }
}

.testimonials-grid__item {
  border: 0.15em solid var(--testimonials-bg);
  /* background-color: unset; */
}
