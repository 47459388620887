.faq__group {
  margin-top: var(--spacing);
}

.faq__details {
  display: block;
  margin-bottom: 0 !important;
}

.faq__summary,
.faq__details {
  p,
  .button,
  figure,
  ul,
  ol {
    max-width: var(--w-prose);
    margin-bottom: var(--spacing);
  }
  .svg-image-container {
    margin-bottom: var(--margin-m);
  }
}

h2 + .faq__details,
.faq__details:first-of-type {
  .faq__summary {
    border-top: none;
  }
}

h3.faq__question {
  margin: 0 var(--icon-size) 0 0;
  text-align: left;
}

.faq__summary {
  padding: 1em 0;
  border-top: 1px solid var(--black);
  position: relative;
}

.expand-icon__container {
  --icon-size: 2em;

  position: absolute;
  top: calc((100% - var(--icon-size)) / 2);
  right: 0;

  transition: opacity 0.3s ease-out;
}
.expand-more {
  opacity: 1;
}
.expand-less {
  opacity: 0;
}
details[open] .expand-icon__container.expand-more {
  opacity: 0;
}
details[open] .expand-icon__container.expand-less {
  opacity: 1;
}

.faq__content {
  .supporters {
    --grid-cell: var(--c2);
  }
}
