.donate-cta {
  max-width: var(--w-content);

  display: flex;
  gap: var(--spacing);
  flex-flow: column nowrap;

  /* @media (--media-md) {
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  } */

  &__info {
    max-width: var(--w-prose);

    display: flex;
    gap: var(--spacing);
    flex-flow: column nowrap;
  }

  &__button {
    margin-left: auto;
  }
}
