.argument {
  margin-top: var(--margin-m);
  margin-bottom: var(--margin-m);

  display: flex;
  flex-flow: wrap;
  gap: var(--grid-gap);
}
.argument__content {
  position: relative;
  flex: 1 1 var(--c7);
}

.argument__figure {
  flex: 1 1 var(--c5);
  &.right {
    img {
      margin-left: auto;
    }
  }
}

.argument__text {
  background: var(--argument-bg);
  color: var(--argument-color);

  padding: var(--spacing);
  & *:first-child {
    margin-top: 1em;
  }
  & *:last-child {
    margin-bottom: 1em;
  }
}

.argument__index {
  position: absolute;
  font-size: 3.5rem;

  top: calc(var(--spacing) * -0.25 - 1em);
  left: var(--spacing-s);

  padding: 0.2em var(--spacing-s);

  background-color: var(--argument-index-bg);
  color: var(--argument-index-color);
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}
