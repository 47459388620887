.registration-form.engagement-box {
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  form > span {
    font-size: 13px;
    display: block;
    margin-bottom: 15px;
  }

  background: var(--pro-futuris-gradient);
}
