@font-face {
  font-family: Montserrat;
  font-style: normal;
  src:
    local("Montserrat-VariableFont"),
    url(../fonts/Montserrat-VariableFont_wght.woff2) format("woff2");
  font-display: swap;
  font-variation-settings: "wght" 500;
  font-weight: normal;
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  src:
    local("Montserrat-Italic-VariableFont"),
    url(../fonts/Montserrat-Italic-VariableFont_wght.woff2) format("woff2");
  font-display: swap;
  font-variation-settings: "wght" 500;
  font-weight: normal;
}
@font-face {
  font-family: PlayfairDisplay;
  src:
    local("PlayfairDisplay-VariableFont"),
    url(../fonts/PlayfairDisplay-VariableFont_wght.woff2) format("woff2");
  font-display: swap;
  font-variation-settings: "wght" 500;
  font-weight: normal;
}

html {
  font-size: clamp(0.625rem, 1vw, 1rem);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat, sans-serif;
  text-rendering: optimizeLegibility;
  line-height: 1.4;

  font-size: 1.33rem;
}

a[class]:not(.button) {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3 {
  font-family: PlayfairDisplay, serif;
}

.prose {
  h1,
  h2,
  h3 {
    max-width: var(--w-prose);
    margin-top: 3em;
    margin-bottom: var(--spacing);
  }

  & > p,
  & > .button,
  & > figure,
  & > ul,
  & > ol,
  & > summary,
  & > details,
  & > hr,
  & > table {
    max-width: var(--w-prose);
    margin-bottom: var(--spacing);

    @media (--media-md) {
      margin-left: calc(2 * var(--p) + var(--c));
    }
  }

  a:not([class]) {
    scroll-margin: var(--margin-l);
    color: var(--link-color);
  }
}

.caption,
figcaption {
  text-align: center;
  margin: 0.2rem 0;
}

.caption,
figcaption,
small {
  font-size: 0.8rem;
  /* color: var(--gray); */
}

.lead {
  font-size: 1.1rem;
  /* color: var(--gray); */
  font-weight: bold;
}
