:root {
  --white: #ffffff;
  --black: #08080d;
  --gray: #404060;
  --gray--light: #a9a9a9;
  --gray--text: #6f6767;

  --orange: #ffb802;
  --blood-orange: #ed2727;
  --grapefruit: #e87373;

  --pro-futuris-gradient: linear-gradient(
    to right,
    var(--blood-orange),
    var(--orange) 133%
  );

  --button-color: var(--white);
  --button-bg: var(--black);

  --link-color: var(--black);

  --errors-fg: var(--grapefruit);

  --hero-bg: transparent;
  --hero-color: var(--white);

  --header-bg: var(--orange);
  --header-color: var(--black);

  --menu-button-bg: var(--blood-orange);
  --menu-button-color: var(--white);

  --footer-bg: var(--pro-futuris-gradient);
  --footer-color: var(--white);

  --bg-featured: var(--blood-orange);
  --color-featured: var(--black);

  --article-teaser-bg: var(--blood-orange);

  --products-bg: var(--blood-orange);

  --engagement-bg: var(--blood-orange);
  --engagement-color: var(--black);

  --argument-bg: var(--pro-futuris-gradient);
  --argument-color: var(--white);
  --argument-index-bg: var(--orange);
  --argument-index-color: var(--white);

  --engagement-bg-0: var(--grapefruit);
  --engagement-color-0: var(--white);

  --engagement-bg-1: var(--orange);
  --engagement-color-1: var(--white);

  --engagement-bg-2: var(--black);
  --engagement-color-2: var(--white);

  --engagement-bg-3: var(--grapefruit);
  --engagement-color-3: var(--white);

  --engagement-bg-4: var(--white);
  --engagement-color-4: var(--black);

  --pledge-color: var(--white);
  --pledge-bg: var(--blood-orange);
}
