main {
  flex: 1 1 100%;
}

main > .container:first-child {
  margin-top: var(--margin-m);
  @media (--media-md) {
    margin-top: var(--margin-xl);
  }
  .no-hero & {
    margin-top: calc(var(--margin-m) + 8rem);
    @media (--media-md) {
      margin-top: calc(var(--margin-xl) + 8rem);
    }
  }
}

.hero-container + .container {
  margin-top: var(--margin-m);
}

main > .container:last-child {
  margin-bottom: var(--margin-m);
}

.container {
  max-width: var(--w);
  width: 100%;

  padding: 0 var(--p);

  margin-left: auto;
  margin-right: auto;
}

.container > .container {
  --p: 0;
}

.container {
  &.maxed {
    padding: 0;
    max-width: var(--w-max);
  }
  &.sm--full {
    padding: 0;
    @media (--media-md) {
      padding: 0 var(--p);
    }
  }
  &.md--full {
    padding: 0;
    @media (--media-lg) {
      padding: 0 var(--p);
    }
  }
}
