.plugin {
  margin: var(--margin-m) auto;
  width: 100%;

  &--centered {
    display: flex;
    & > * {
      margin: auto;
    }
  }
}

.plugin--button {
  text-align: center;
}

.plugin--wrap {
  padding: var(--margin-m) 0;
  background-color: var(--bg-featured);
  color: var(--color-featured);

  h1,
  h2,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }
}

main > .plugin--wrap:last-child {
  margin-bottom: 0;
}

.responsive-embed {
  aspect-ratio: 16 / 9;
  position: relative;
}

.responsive-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
