.icon {
  width: var(--icon-size);
  height: var(--icon-size);

  fill: var(--icon-color);
}

.button-icon {
  --icon-size: 1em;
  transform: translateX(0.33em);
}

/* icon corrections */
#icon-tiktok {
  transform: scale(0.8);
  transform-origin: 50%;
}
