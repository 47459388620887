.lm {
  border: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 30, 0.5);
  max-width: 40rem;
  position: relative;

  background: var(--almondy);
  z-index: var(--z-modal);
}

.lm::backdrop {
  /* native */
  background-color: rgba(226, 226, 226, 0.6);
}

.lm__form {
  display: flex;
  gap: 0;

  input[type="email"] {
    flex-grow: 1;
  }
}

.lm__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.lm__title,
.lm__description {
  margin-bottom: 1rem;
}
