:root {
  --header-padding: 16px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding-top: var(--header-padding);
  padding-bottom: var(--header-padding);

  z-index: var(--z-header);

  background-color: transparent;
  color: var(--header-color);

  transition: background-color 0.3s ease-in-out;

  .scrolled &,
  .no-hero & {
    background-color: var(--header-bg);
  }
  @media (--media-sm-only) {
    background-color: var(--header-bg);
  }
}

.header-container,
.mobile-menu .toggle-container {
  height: 8rem;
  display: flex;
  align-items: center;

  transition: height 0.3s ease-out;

  .scrolled & {
    height: 3em;
  }

  @media (--media-sm-only) {
    height: 3em;
  }
}

.header-container {
  justify-content: space-between;
  gap: var(--spacing);
}
.toggle-container {
  justify-content: right;
}

header .logo-image {
  max-height: 100%;
}

.logo-container {
  height: 100%;
  margin-right: auto;
}

header .logo-container {
  display: flex;
  gap: 1rem;
  align-items: center;

  img {
    flex: 1 1 auto;
    max-height: 100%;
  }
}

.menu-is-open {
  overflow: hidden;
}

.mobile-menu {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;

  background: var(--header-bg);
  z-index: var(--z-mobile-menu);

  overflow-y: auto;

  padding-bottom: var(--margin-l);
  padding-top: var(--header-padding);

  .nav-toggle {
    margin-left: auto;
  }

  .nav-list-item {
    --button-color: var(--menu-button-color);
    --button-bg: var(--menu-button-bg);
  }
}

.cta-container {
  flex: 0 1 auto;
  margin-left: auto;
  .button {
    border-color: var(--black);
  }
}

.menu-is-open .mobile-menu {
  display: block;
}

.nav-toggle {
  .button.button--inverted {
    border: none;
    &:hover,
    &:focus-visible {
      background-color: var(--button-bg);
    }
  }
}

.main-nav {
  .nav-list {
    justify-content: right;
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.nav-list {
  display: flex;
  gap: var(--spacing);
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-height: 800px) {
    gap: 1em;
  }
}

.language-nav {
  font-size: 0.8em;

  .nav-list {
    margin-top: var(--margin-m);
    justify-content: center;
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.mobile-nav {
  font-size: 1.33rem;

  .nav-list {
    flex-direction: column;
  }
}
