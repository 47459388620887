.polls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;

  @media (--media-md) {
    max-width: 50%;
    margin-left: calc(2 * var(--p) + var(--c));
  }
}

.polls__item-button {
  width: 100%;
  border: 0;
  border-radius: 15px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--engagement-color-1);

  background: var(--engagement-bg-1);

  &:disabled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: var(--footer-bg);
  }
}

.polls__item-content {
  :disabled & {
    text-align: left;
  }
}
.polls__item-description {
  text-align: left;
  padding: 10px 20px;
  /* margin-top: 0.5rem; */
  margin-bottom: 10px;
}
